import React from 'react';
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import {Helmet} from "react-helmet";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Post } from "../../components/Core";
import DataTable from '../../components/data/banquePro/banquePro'
import DataTableMobile from '../../components/data/banquePro/banqueProMobile'
import Disclosure from '../../components/Disclosure'
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding} from '@fortawesome/free-solid-svg-icons'

function startabusiness() {

	var d = new Date();
var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var n = month[d.getMonth()];
var y = d.getFullYear();


    return (
        
		<PageWrapper footerDark>
		<Helmet>
			  <meta charSet="utf-8" />
			  <title>Banque pour SCI - Meilleure banque pro pour votre SCI</title>
			  <meta name="description" content="Vous avez une société de type SCI? Trouvez la meilleure banque pro avec bankbank." />
		  </Helmet>
		<Header/>
		<Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
		<Container style={{maxWidth: 1280, marginTop: 28}}>
	   
		<Breadcrumb>
		  <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
		  <Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Banque Pro</Link></Breadcrumb.Item>
		  <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Banque pro pour SCI</Breadcrumb.Item>
		</Breadcrumb>


		<h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}>Banque Pro pour une SCI</h1>
		<h2 id='compareh2' style={{color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure banque pro avec un comparatif tarifs, cartes bancaires et comptabilité intégrée (mise à jour {n} {y})</h2>
		<div id='onetwothreemobile'>
		<p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur banques pro SCI</span> pour déterminer vos priorités : actionnaires, tarifs, services inclus.</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponibles dès aujourd’hui.</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure banque pro</span>, celle qui correspond le plus à vos besoins.</p>
		</div>

		<Disclosure/>
		<Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
		
		</Row>
		</Container>
		</Container>  


   
		<Container style={{maxWidth: 1280, marginTop: -130}}>
	  
		<Tabs style={{maxWidth: 1234}}>
  <TabList style={{marginLeft: 5}}>
	<Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> SCI avec 1 actionnaire </Tab>
	<Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 2 - 5 actionnaires</Tab>
	<Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 6 - 50 actionnaires</Tab>
  </TabList>

  <TabPanel>
	<DataTable type='Société' row={[35,15,2,16,36,18,30,9,38,40]} id='wideTable'/>
	<DataTableMobile type='Société' row={[35,15,2,16,36,18,30,9,38,40]} id='mobileTable'/>
  </TabPanel>
	<TabPanel>      
	<DataTable type='Société' row={[12,4,18,9,19,30,20]} id='wideTable'/>
	<DataTableMobile type='Société' row={[12,4,18,9,19,30,20]} id='mobileTable'/>
  </TabPanel>
	<TabPanel>    
	<DataTable type='Société' row={[9,21,4,22,23,31]} id='wideTable'/>
	<DataTableMobile type='Société' row={[9,21,4,22,23,31]} id='mobileTable'/>
  </TabPanel>
  {/* <TabPanel>
	<DataTable type='Société' id={[9,21,5,22,23,24,25,26]}/>
	<DataTableMobile type='Société' id={[35,15,2,16,36,18,30,9,38]}/>
  </TabPanel> */}

</Tabs>
		  <Row className="justify-content-center">
			<Col>

		  

	 
			</Col>
		  </Row>
		   
		</Container>
		<Container style={{marginTop: 100, marginBottom : 100}}>
			
			<Post>
			<h2>Comment choisir la meilleure banque pour une SCI ?</h2>
			<p>
La SCI est le statut d’entreprise de choix pour des investissements immobiliers dans le but d’acheter à plusieurs et/ou de préparer la transmission de biens à vos enfants.
</p><p>
<StaticImage
		  	src='../../images/banque-pro-sci.jpeg'
			width={1200}
			alt='Banque à distance'
			placeholder='tracedSVG'
			id='pickky'
			/>
C’est un statut qui n’est pas accepté par toutes les banques. Ce comparatif vous permettra de faire un tri rapide dans les offres du marché, et trouver rapidement une banque qui vous aidera dans vos investissements.
</p>	</Post>
		</Container>
	
		<Footer/>
      </PageWrapper>
    
    )
}

export default startabusiness
